import "./styles.scss";

import React from "react";

import ItemsSlider from "components/ItemsSlider";
import InvestmentGridItem from "components/InvestmentGridItem";

const mainClass = "group-hotel__our-hotels";

const OurHotels = ({ data }) => {
  return (
    <div className={mainClass}>
      <div className="container">
        <div className={`${mainClass}__header`}>
          <h2>
            Nasze <span>hotele</span>
          </h2>
          <p>
            Poznaj obiekty Grupy Hotelowej Condo.pl i wypocznij w luksusowych
            wnętrzach z niepowtarzalnym klimatem
          </p>
        </div>
        <ItemsSlider>
          {data.map((props, index) => (
            <div key={index}>
              <InvestmentGridItem {...props} />
            </div>
          ))}
        </ItemsSlider>
      </div>
    </div>
  );
};

export default OurHotels;
