import "./styles.scss";
import React from "react";

import WhiteCIcon from "icons/white-c.svg";

const mainClass = "group-hotel__header";

const Header = () => {
  return (
    <div className={mainClass}>
      <div className="container">
        <WhiteCIcon />
        <h1>
          GRUPA
          <br />
          <span>HOTELOWA</span>
        </h1>
      </div>
    </div>
  );
};

export default Header;
