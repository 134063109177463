import "./styles.scss";

import React from "react";
import classnames from "classnames";

const mainClass = "facilities-grid";

const FacilitiesGrid = ({ data, variant }) => {
  return (
    <div
      className={classnames(mainClass, {
        [`${mainClass}--${variant}`]: !!variant,
      })}
    >
      {data?.map(({ icon, title, text }, index) => (
        <div key={index} className={`${mainClass}__item`}>
          <div className={`${mainClass}__item__icon`}>{icon}</div>
          <span
            dangerouslySetInnerHTML={{
              __html: title.split("|").join("<br/>"),
            }}
          />
          {text && <p>{text}</p>}
        </div>
      ))}
    </div>
  );
};

export default FacilitiesGrid;
