import "./styles.scss";

import React from "react";

import FacilitiesGrid from "components/FacilitiesGrid";
import getIcon from "libs/helpers/getIcon";

import CondoPinIcon from "icons/condo-pin.svg";
import PersonBulbIcon from "icons/operator-services/person-bulb.svg";

const mainClass = "group-hotel__operator-services";

const OperatorServices = ({ items }) => {
  const formatted_items = items?.map(({ icon, text: title }) => ({
    icon: getIcon(icon),
    title,
  }));

  const top_items = formatted_items?.splice(0, 4);
  const bottom_items = formatted_items?.splice(0, formatted_items?.length);

  return (
    <div className={mainClass}>
      <div className="container">
        <div className={`${mainClass}__header`}>
          <CondoPinIcon />
          <h2>
            Usługi
            <br />
            <span>operatorskie</span>
          </h2>
          <p>
            Nasze usługi to wypadkowa wiedzy naszych ekspertów i wieloletniego
            doświadczenia w branży hotelarskiej, nieruchomości, gastronomicznej.{" "}
          </p>
        </div>
        <FacilitiesGrid data={top_items} variant="dark" />
        <div className={`${mainClass}__divider`}>
          <PersonBulbIcon />
        </div>
        <FacilitiesGrid data={bottom_items} variant="dark" />
      </div>
    </div>
  );
};

export default OperatorServices;
