import "styles/pages/home-page.scss";

import React from "react";
import { graphql } from "gatsby";

import {
  Development,
  Header,
  OperatorServices,
  OurMission,
  OurTeam,
  OurHotels,
} from "../page_components/hotel-group";

import Layout from "../components/Layout";

const About = ({ data }) => {
  const services = data?.servicesPage?.servicesPage;
  const allInvestments = data?.servicesPage?.servicesPage?.ourHotels;
  const schema = JSON.parse(data.servicesPage.seo.schema.raw);
  const seo = data.servicesPage.seo;

  return (
    <Layout
      seo={{ title: seo?.title, desc: seo?.metaDesc, schema }}
      location="Grupa hotelowa"
      form_id={5648}
    >
      <Header />
      <OurMission
        text={services?.hotelGroupText}
        items={services?.ourMissionSteps}
      />
      <Development items={services?.development} />
      <OperatorServices items={services?.operatorServices} />
      <OurTeam items={services?.team} />
      <OurHotels data={allInvestments} />
    </Layout>
  );
};

export default About;

export const query = graphql`
  query hotelGroupInvestments {
    servicesPage: wpPage(id: { eq: "cG9zdDoxNDE1" }) {
      servicesPage {
        hotelGroupText
        ourMissionSteps {
          title
          desc
        }
        development {
          year
          opening
          apartamenty {
            name
          }
          objects
          rooms
        }
        operatorServices {
          icon
          text
        }
        team {
          avatar {
            sourceUrl
          }
          name
          position
          phone
          email
          type
        }
        ourHotels {
          ... on WpInvestment {
            title
            id
            uri
            slug
            investments {
              category
              homePick
              backPercentage
              contactLength
              deadline
              investLogo {
                sourceUrl
                altText
              }
              mainImg {
                sourceUrl
              }
              mainTitle
              priceMin
              subtitle
              shortLocation
              surfacemin
              bar
              bikeRoom
              conferenceRoom
              gym
              kids
              parking
              poolIn
              poolOut
              reception
              restaurant
              sauna
              skiRoom
              spa
              terrace
              ourHotels
              ourHotelsRooms
              ourHotelsPhoto {
                sourceUrl
              }
              ourHotelsLink
            }
          }
        }
      }
      title
      seo {
        title
        metaDesc
        schema {
          raw
        }
        opengraphTitle
        opengraphDescription
        opengraphType
        opengraphUrl
        opengraphImage {
          sourceUrl
        }
      }
    }
  }
`;
