import "./styles.scss";

import React from "react";
import classnames from "classnames";

import KarolImage from "images/karol.png";
import ArturImage from "images/artur.png";
import DanielImage from "images/daniel.png";
import MatyldaImage from "images/matylda.png";

import MessageIcon from "icons/message.svg";
import PhoneIcon from "icons/phone-icon.svg";

const mainClass = "group-hotel__our-team";

const OurTeam = ({ items }) => {
  const filttered_items = items?.map((item) => ({
    ...item,
    avatar: item?.avatar?.sourceUrl,
  }));

  const our_team_data = [
    {
      title: "Zarząd",
      items: filttered_items?.filter((item) => item?.type === "management"),
    },
    {
      title: "Zarządzanie hotelami",
      items: filttered_items?.filter(
        (item) => item?.type === "hotel_management"
      ),
      variant: "orange",
    },
  ];

  return (
    <div className={mainClass}>
      <div className="container">
        <div className={`${mainClass}__header`}>
          <h2>
            Nasz <span>zespół</span>
          </h2>
          <p>
            Łączymy znajomość lokalnego rynku nieruchomości, duch
            przedsiębiorczości, ale przede wszystkich doświadczenie w obszarze:
            zarządzania hotelami autorskimi i sieciowymi o zasięgu
            międzynarodowym, zarządzania gastronomią, sprzedaży usług hotelowych
            oraz marketingu.
          </p>
        </div>
        <div className={`${mainClass}__items`}>
          {our_team_data?.map(({ title, items, variant }, index) => (
            <div key={index} className={`${mainClass}__items__column`}>
              <h3>{title}</h3>
              <div className={`${mainClass}__items__column__items`}>
                {items?.map(
                  ({ avatar, name, position, email, phone }, index) => (
                    <div
                      key={index}
                      className={classnames(
                        `${mainClass}__items__column__items__item`,
                        {
                          [`${mainClass}__items__column__items__item--${variant}`]:
                            !!variant,
                        }
                      )}
                    >
                      <img src={avatar} alt="" />
                      <span>{name}</span>
                      <label>{position}</label>
                      <div
                        className={`${mainClass}__items__column__items__item__contact`}
                      >
                        {!!email && (
                          <span>
                            <MessageIcon />
                            <a href={`mailto:${email}`}>{email}</a>
                          </span>
                        )}
                        {!!phone && (
                          <span>
                            <PhoneIcon />
                            <a href={`tel:${phone}`}>
                              <b>{phone}</b>
                            </a>
                          </span>
                        )}
                      </div>
                    </div>
                  )
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurTeam;
