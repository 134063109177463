const objectSuffixRender = (number) => {
  if (number === 1) {
    return "obiekt";
  } else if (number > 4) {
    return "obiektów";
  } else {
    return "obiekty";
  }
};

export default objectSuffixRender;
