import "./styles.scss";
import React from "react";

import objectSuffixRender from "utils/objectSuffixRender";
import roomSuffixRender from "utils/roomSuffixRender";

import MountainIcon from "icons/mountain.svg";

const mainClass = "group-hotel__development";

const Development = ({ items }) => {
  return (
    <div className={mainClass}>
      <div className="container">
        <div className={`${mainClass}__header`}>
          <MountainIcon />
          <h2>
            Napędza nas
            <br />
            <span>rozwój</span>
          </h2>
        </div>
        <div className={`${mainClass}__items`}>
          {items?.map(({ year, opening, apartamenty, objects, rooms }) => (
            <div className={`${mainClass}__items__item`}>
              <label>{year}</label>
              <span>{opening}</span>
              <ul>
                {apartamenty?.map(({ name }, index) => (
                  <li key={index}>{name}</li>
                ))}
              </ul>
              <div className={`${mainClass}__items__item__stats`}>
                <span>
                  <span>{objects}</span> {objectSuffixRender(objects)}
                </span>
                <span>
                  <span>{rooms}</span> {roomSuffixRender(rooms)}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Development;
