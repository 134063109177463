import "./styles.scss";
import React from "react";

import PaperRocketIcon from "icons/paper-rocket.svg";

const mainClass = "group-hotel__our-mission";

const OurMission = ({ text, items }) => {
  return (
    <div className={mainClass}>
      <div className="container">
        <div className={`${mainClass}__header`}>
          <h2>
            Grupa <span>Hotelowa</span>
          </h2>
          <p
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        </div>
        <div className={`${mainClass}__content`}>
          <PaperRocketIcon />
          <h3>
            Nasza misja, <span>nasze cele</span>
          </h3>
          <div className={`${mainClass}__content__items`}>
            {items?.map(({ title, desc }, index) => (
              <div key={index} className={`${mainClass}__content__items__item`}>
                <span className={`${mainClass}__content__items__item__number`}>
                  {index + 1}
                </span>
                <p>
                  <span>{title}</span>
                  <br />
                  {desc}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurMission;
