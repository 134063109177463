import "./styles.scss";

import React from "react";
import Slider from "react-slick";
import classnames from "classnames";

import ArrowIcon from "icons/slider-arrow.svg";

const mainClass = "items-slider";

function CustomArrow(props) {
  const { className, onClick } = props;
  return <ArrowIcon className={className} onClick={onClick} />;
}

const ItemsSlider = ({ children, theme }) => {
  const categorySlider = {
    dots: true,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    draggable: true,
    nextArrow: (
      <CustomArrow
        className={`${mainClass}__arrow ${mainClass}__arrow--next`}
      />
    ),
    prevArrow: (
      <CustomArrow
        className={`${mainClass}__arrow ${mainClass}__arrow--prev`}
      />
    ),
    responsive: [
      {
        breakpoint: 1090,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 830,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 520,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div
      className={classnames(mainClass, {
        [`${mainClass}--${theme?.header_color}`]: !!theme?.header_color,
      })}
    >
      <Slider {...categorySlider}>{children}</Slider>
    </div>
  );
};

export default ItemsSlider;
